.attendance-description {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.attendance-description .learn-more {
  display: inline;
  float: right;
  font-size: 14px;
}
.attendance-description .info {
  color: #e6e6e6;
}

.attendance-list-empty td {
  padding: 15px 10px;
}

.list-group {
  margin-top: 10px;
  padding-bottom: 10px;
}
.list-group .show-older {
  font-size: medium;
  min-width: 90%;
  margin-top: 15px;
  padding: 7px;
  margin-bottom: 8px;
  margin-left: 5%;
}

.status-warning {
  padding-top: 5px;
}
.status-warning .glyphicon-alert {
  color: #ca8700;
  margin-right: 7px;
}
.status-warning .header {
  font-weight: bold;
}
.status-warning .info {
  font-weight: normal;
}

.point-progress {
  display: flex;
  margin: 0;
}
.point-progress .rectangle {
  display: inline-block;
  height: 40px;
  margin: 3px;
}
.point-progress .filled.warning {
  background: #ca8700;
}
.point-progress .filled.ok {
  background: #0070d2;
}
.point-progress .filled.unknown {
  background: #0070d2;
}
.point-progress .filled.error {
  background: #cd3e3e;
}
.point-progress .filled.termination {
  background: #cd3e3e;
}
.point-progress .filled.policy-violation {
  background: #cd3e3e;
}
.point-progress .empty {
  border-style: solid;
  border-width: 2px;
}
.point-progress .empty.warning {
  border-color: #ca8700;
}
.point-progress .empty.ok {
  border-color: #0070d2;
}
.point-progress .empty.unknown {
  border-color: #0070d2;
}
.point-progress .empty.termination {
  border-color: #cd3e3e;
}
.point-progress .empty.error {
  border-color: #cd3e3e;
}
.point-progress .empty.policy-violation {
  border-color: #cd3e3e;
}
.point-progress .normal-rectangle {
  width: 15px;
  flex: 0 0 7%;
  float: left;
}
.point-progress .policy-violation {
  text-align: center;
  width: auto;
  flex: 1 0 7%;
}
.point-progress .policy-violation .message {
  display: inline-block;
  word-wrap: break-word;
  color: #cccccc;
}
@media screen and (max-width: 400px) {
  .point-progress .policy-violation div.message {
    font-size: 10px;
  }
  .point-progress .policy-violation div.glyphicon-alert {
    font-size: 10px;
  }
}
@media screen and (min-width: 601px) {
  .point-progress .policy-violation div.message {
    font-size: 18px;
  }
  .point-progress .policy-violation div.glyphicon-alert {
    font-size: 18px;
  }
  .point-progress .policy-violation div.policy-violation {
    padding-top: 5px;
  }
}
@media screen and (max-width: 600px) and (min-width: 401px) {
  .point-progress .policy-violation div.message {
    font-size: 12px;
  }
  .point-progress .policy-violation div.glyphicon-alert {
    font-size: 12px;
  }
  .point-progress .policy-violation div.policy-violation {
    padding-top: 5px;
  }
}
.point-progress .policy-violation .glyphicon-alert {
  color: #cccccc;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 2px;
}
.point-progress .policy-violation.filled .message {
  color: white;
}
.point-progress .policy-violation.filled .glyphicon-alert {
  color: white;
}

@media screen and (min-width: 401px) {
  div.policy-violation {
    padding-top: 5px;
  }
}
.recap {
  padding-top: 3px;
  font-family: "Amazon Ember", "Helvetica", sans-serif;
}
.recap .info {
  display: inline;
  color: #666;
  font-size: 1.28em;
}
.recap .important {
  display: inline;
  font-size: 18px;
}
.recap .warning {
  color: #ca8700;
}
.recap .termination {
  color: #cd3e3e;
}
.recap .error {
  color: #cd3e3e;
}
.recap .negative {
  color: #cd3e3e;
}

.list-attendance-item {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  border-bottom: #cccccc solid 1px;
}

.attendance-list-table {
  margin-left: 0%;
  margin-top: 2%;
  border: solid 0.5px #f3f4f5;
  width: 100%;
  table-layout: fixed;
}
.attendance-list-table .date-header {
  width: 30%;
  padding-left: 2%;
}
.attendance-list-table .type-header {
  width: 30%;
}
.attendance-list-table .time-header {
  width: 50%;
}
@media screen and (min-width: 601px) {
  .attendance-list-table .date-column {
    min-width: 235px;
    max-width: 235px;
    padding-left: 2%;
    padding-top: 2%;
  }
  .attendance-list-table .type-column {
    min-width: 235px;
    max-width: 235px;
    padding-left: 2%;
  }
  .attendance-list-table .time-column {
    min-width: 400px;
    max-width: 400px;
    padding-left: 5%;
  }
  .attendance-list-table .button-link {
    min-width: 100px;
    max-width: 100px;
  }
  .attendance-list-table .item-separator {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2%;
  }
}
@media screen and (max-width: 600px) and (min-width: 401px) {
  .attendance-list-table .date-column {
    min-width: 100px;
    max-width: 100px;
    padding-left: 2%;
    padding-top: 2%;
  }
  .attendance-list-table .type-column {
    min-width: 100px;
    max-width: 100px;
    padding-left: 2%;
  }
  .attendance-list-table .time-column {
    min-width: 120px;
    max-width: 120px;
    padding-left: 2%;
  }
  .attendance-list-table .button-link {
    min-width: 70px;
    max-width: 70px;
  }
  .attendance-list-table .item-separator {
    width: 91%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: inherit;
  }
}
@media screen and (max-width: 400px) {
  .attendance-list-table .date-column {
    min-width: 80px;
    max-width: 80px;
    padding-left: 2%;
    padding-top: 2%;
  }
  .attendance-list-table .type-column {
    min-width: 80px;
    max-width: 80px;
    padding-left: 2%;
  }
  .attendance-list-table .time-column {
    min-width: 120px;
    max-width: 120px;
    padding-left: 2%;
  }
  .attendance-list-table .button-link {
    min-width: 50px;
    max-width: 50px;
    text-align: right;
    padding-right: 4px;
  }
  .attendance-list-table .item-separator {
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .attendance-list-table .date-header {
    width: 25%;
    padding-left: 2%;
  }
  .attendance-list-table .type-header {
    width: 25%;
  }
  .attendance-list-table .time-header {
    width: 50%;
  }
}

.attendance-item {
  display: flex;
}
.attendance-item .attendance-info {
  padding-top: 5px;
  flex-grow: 8;
}
.attendance-item .attendance-description {
  flex-flow: row wrap;
}
.attendance-item .general-info {
  flex-grow: 3;
  max-width: 500px;
}
.attendance-item .timeoff-request-submitted {
  color: #008000;
  font-weight: 300;
  flex-grow: 2;
}
.attendance-item .timeoff-request-submitted-upt {
  color: #008000;
  font-weight: 300;
  flex-grow: 2;
  padding-left: 2%;
  padding-top: inherit;
}
.attendance-item .expiry {
  font-weight: 300;
  color: #cccccc;
  flex-grow: 1;
  float: right;
}
.attendance-item .attendance-item-action {
  margin-top: 10px;
  padding: 3px 16px;
  width: 100%;
}
@media screen and (min-width: 501px) {
  .attendance-item .expiry {
    text-align: right;
  }
  .attendance-item .attendance-description {
    display: flex;
  }
}
.attendance-item .glyphicon-chevron-right {
  font-size: 14px;
  padding-top: 2px;
  flex-grow: 1;
  text-align: right;
  max-width: 40px;
}
.attendance-item .date {
  padding-top: 1px;
  right: 1px;
  font-weight: bold;
}
.attendance-item .impact {
  padding-left: 1px;
  font-weight: 300;
  word-break: break-all;
}
.attendance-item .upt-impact {
  font-weight: 300;
  word-break: break-word;
  font-size: medium;
}
.attendance-item .upt-deduction-indicator {
  color: #cd3e3e;
}
.attendance-item .day {
  font-size: small;
}
.attendance-item .short-date {
  font-size: medium;
  font-weight: bold;
}
.attendance-item .text-medium {
  font-size: medium;
}
.attendance-item .adjustment {
  margin-top: 5px;
  display: flex;
}
.attendance-item .adjustment .glyphicon-alert {
  margin-right: 5px;
  color: #ca8700;
}
.attendance-item .adjustment .message {
  font-size: 12px;
}

.timeoff-details {
  border-radius: 4px;
  /*background: var(--surface, #fff);*/
  box-shadow: 0 1px 3px 1px rgba(35, 47, 62, 0.15), 0 1px 2px 0 rgba(35, 47, 62, 0.3);
}

.attendance-detail {
  max-width: 500px;
  margin: auto;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.attendance-detail .section {
  margin-bottom: 10px;
}
.attendance-detail .section .title {
  color: #e6e6e6;
  margin-bottom: 3px;
}
.attendance-detail .section .main {
  font-weight: bold;
}
.attendance-detail .section .info {
  font-weight: normal;
}
.attendance-detail .refresh-time .section,
.attendance-detail .points .section {
  display: inline-grid;
  margin-right: 40px;
}
.attendance-detail .refresh-time .section .info,
.attendance-detail .points .section .info {
  color: #008574;
}
.attendance-detail .adjustment {
  border-top: #808080 solid 2px;
  padding-top: 10px;
}
.attendance-detail .adjustment .header {
  font-size: 14px;
}
.attendance-detail .adjustment .item {
  margin-top: 10px;
}
.attendance-detail .adjustment .item-header {
  margin-bottom: 7px;
}
.attendance-detail .message-disclaimer {
  margin-top: 40px;
  color: #cccccc;
  text-align: center;
}
.attendance-detail .timeoff-request-submitted-banner {
  padding-bottom: 10px;
  padding-left: 2px;
  padding-right: 2px;
}
.attendance-detail .attendance-footer {
  padding-top: 5px;
  padding-bottom: 5px;
}
.attendance-detail .separator {
  border-top: #808080 solid 2px;
}
.attendance-detail .refresh-time .separator {
  margin-top: 10px;
  margin-bottom: 10px;
}

.policy .mainTitle {
  font-size: 18px;
}
.policy .subTitle {
  font-size: 14px;
}

.upt-balance .upt-balance-tracker {
  display: flex;
  margin: 0 20px;
}
.upt-balance .upt-balance-tracker .gauge {
  display: inline-block;
  height: 20px;
  margin: 5px;
  width: auto;
  flex: 1;
  float: left;
  transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
}
.upt-balance .upt-balance-tracker .filled.normal {
  background-color: #0070d2;
}
.upt-balance .upt-balance-tracker .filled.warning {
  background-color: #ca8700;
}
.upt-balance .upt-balance-tracker .empty {
  border: #0070d2 solid 2px;
}
@media screen and (min-width: 401px) {
  .upt-balance .upt-balance-tracker {
    margin: 0 10px;
  }
  .upt-balance .upt-balance-tracker .gauge {
    height: 20px;
  }
}
.upt-balance .upt-balance-tracker {
  display: flex;
  margin: 0 20px;
}
.upt-balance .upt-balance-tracker .gauge {
  display: inline-block;
  height: 20px;
  margin: 5px;
  width: auto;
  flex: 1;
  float: left;
  transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
}
.upt-balance .upt-balance-tracker .filled.normal {
  background-color: #0070d2;
}
.upt-balance .upt-balance-tracker .filled.warning {
  background-color: #ca8700;
}
.upt-balance .upt-balance-tracker .empty {
  border: #0070d2 solid 2px;
}
@media screen and (min-width: 401px) {
  .upt-balance .upt-balance-tracker {
    margin: 0 10px;
  }
  .upt-balance .upt-balance-tracker .gauge {
    height: 20px;
  }
}
.upt-balance .status-indicator-violation {
  background-color: #cd3e3e;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin: 10px 8px;
}
.upt-balance .status-indicator-violation .warning-sign {
  display: inline;
  position: relative;
  top: 3px;
}
.upt-balance .warning-message {
  margin: 10px 8px;
}

.attendance-list-v2 .attendance-list-table {
  margin-top: 0;
}
.attendance-list-v2 .attendance-list-table .attendance-item {
  display: table-row;
  padding: 0;
}
.attendance-list-v2 .attendance-list-table .attendance-item .attendance-info {
  padding: 10px;
}
.attendance-list-v2 .attendance-list-table .attendance-item .attendance-description {
  margin: 0;
  width: 100%;
}
.attendance-list-v2 .attendance-list-table .attendance-item .attendance-description .general-info {
  flex: 1;
  max-width: none;
}
.attendance-list-v2 .show-older-row {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
}
